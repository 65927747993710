import React from "react";
import { SEO, PageLayout } from "@bluefin/components";
import { Grid, Embed } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import { getComponentFiles } from "../utils/utils";

export default class VideosPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const { fishermanBusiness, fishermanBusinessWebsitePage } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          description={fishermanBusinessWebsitePage.description}
          images={getComponentFiles({
            components: fishermanBusinessWebsitePage.components,
            componentName: "HeroElement",
            numToSelect: 1,
          })}
        />
        <PageLayout hero={null}>
          <Grid
            stackable={true}
            className={"component-section-container videos-page"}
            verticalAlign={"middle"}
            textAlign={"left"}
            centered={true}
          >
            <Grid.Column width={14}>
              <Grid
                stackable={true}
                verticalAlign={"middle"}
                textAlign={"left"}
                centered={true}
                column={"2"}
              >
                <Grid.Row stretched={true}>
                  <Grid.Column width={8} textAlign={"center"}>
                    <Embed
                      url={
                        "https://player.vimeo.com/video/855054917?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;autoplay=1&amp;loop=1&amp;controls=0"
                      }
                      autoplay={true}
                      defaultActive={true}
                      className={""}
                    />
                  </Grid.Column>
                  <Grid.Column width={8} textAlign={"center"}>
                    <Embed
                      url={
                        "https://player.vimeo.com/video/855055763?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;autoplay=1&amp;loop=1&amp;controls=0"
                      }
                      autoplay={true}
                      defaultActive={true}
                      className={""}
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row stretched={true}>
                  <Grid.Column width={8} textAlign={"center"}>
                    <Embed
                      url={
                        "https://player.vimeo.com/video/954025659?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;autoplay=1&amp;loop=1&amp;controls=0"
                      }
                      autoplay={true}
                      defaultActive={true}
                      className={""}
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Grid.Column>
          </Grid>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
    }
    fishermanBusinessWebsitePage(title: { eq: "Videos" }) {
      title
      description
      components {
        fastId
        componentIdentifier
        order
        files {
          file
        }
      }
    }
  }
`;
